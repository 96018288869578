'use script';

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            block: 'center',
            behavior: 'smooth'
        });
    });
});  
  
  // Modal

//   $('.products__item-more').on('click', function() {
//     $('.overlay').fadeIn('slow');
//     $('.menu').fadeOut('slow');
//     let index = $('.products__item-more').index(this);
//     console.log(index);
//     $('.modal').eq(index).fadeIn('slow');
//     $("html").css("overflow", "hidden");
// });
  
  $('.overlay').on('click', function(e) {
    if ($('.overlay, .modal').is(":visible")) {
      if (e.target === this) {
        $('.overlay, .modal').fadeOut('slow');
        $('.menu').fadeIn('slow');
        $('html').css("overflow", "overlay");
        $('form').trigger("reset");
        if ($('.modal__join:visible')) $('.modal__join').fadeOut('slow');
      }
    }
  });

  $('.modal__close').on('click', function() {
    $('.overlay, .modal, .modal__form').fadeOut('slow');
    $('.menu').fadeIn('slow');
    $('html').css("overflow", "overlay");
    $('form').trigger("reset");
    if ($('.modal__form:visible')) $('.modal__mini').css("display", "none");
  });

  $('.join').find('.join__btn').on('click', function() {
    $('.overlay').fadeIn('slow');
    $('.menu').fadeOut('slow');
    $('.modal__join').fadeIn('slow');
    $("html").css("overflow", "hidden");
  });

  var x, i, j, l, ll, selElmnt, a, b, c;
  /* Look for any elements with the class "custom-select": */
  x = document.getElementsByClassName("custom-select");
  l = x.length;
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    ll = selElmnt.length;
    /* For each element, create a new DIV that will act as the selected item: */
    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);
    /* For each element, create a new DIV that will contain the option list: */
    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");
    for (j = 1; j < ll; j++) {
      /* For each option in the original select element,
      create a new DIV that will act as an option item: */
      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.addEventListener("click", function(e) {
          /* When an item is clicked, update the original select box,
          and the selected item: */
          var y, i, k, s, h, sl, yl;
          s = this.parentNode.parentNode.getElementsByTagName("select")[0];
          sl = s.length;
          h = this.parentNode.previousSibling;
          for (i = 0; i < sl; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName("same-as-selected");
              yl = y.length;
              for (k = 0; k < yl; k++) {
                y[k].removeAttribute("class");
              }
              this.setAttribute("class", "same-as-selected");
              break;
            }
          }
          h.click();
      });
      b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function(e) {
      /* When the select box is clicked, close any other select boxes,
      and open/close the current select box: */
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
  }
  
  function closeAllSelect(elmnt) {
    /* A function that will close all select boxes in the document,
    except the current select box: */
    var x, y, i, xl, yl, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i)
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }
  
  /* If the user clicks anywhere outside the select box,
  then close all select boxes: */
  document.addEventListener("click", closeAllSelect);

  // Hamburger

  const menu = document.querySelector('.menu__list'),
    menuItem = document.querySelectorAll('.menu__list-item'),
    hamburger = document.querySelector('.menu__hamburger');

    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle('menu__hamburger_active');
        menu.classList.toggle('menu__list_active');
    });